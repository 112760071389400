.AnimatedLink {
  line-height: 100%;

  &:hover {
    outline: none;

    span {
      transform: translate3d(0, -2px, 0);

      &::before {
        transform: translate3d(0, 2px, 0) scale3d(1, 3, 1);
        clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
      }
    }
  }

  span {
    position: relative;
    display: inline-block;
    transition: transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
    white-space: nowrap;
    line-height: 100%;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: currentColor;
      top: calc(100% + 1px);
      left: 0;
      pointer-events: none;

      transform-origin: 50% 100%;
      transition: clip-path 0.3s, transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
      clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
    }
  }
}
