// Globals

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .width-wrapper {
    @apply w-full max-w-max-wrapper-limit my-0 mx-auto px-10 tablet:px-20;
  }

  .image-wrapper {
    img {
      @apply object-contain object-left-top desktop:object-center;
    }
  }

  .font-heading-normal {
    @apply font-heading font-normal;
  }

  .font-heading-bold {
    @apply font-heading font-semibold;
  }

  .font-body-normal {
    @apply font-body font-normal;
  }

  .font-body-bold {
    @apply font-body font-bold;
  }
}

// Global styling, normalize overwritten

html {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch;
  text-size-adjust: none;
  font-size: 10px;

  &.hide-page {
    visibility: hidden;
    opacity: 0;
  }
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 1.6rem;
  word-break: keep-all;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  z-index: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

main {
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
dl,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote {
  margin: 0;
  padding: 0;
}

button,
input,
a {
  &:focus:not(.focus-visible) {
    outline: none;
  }
}

h1 {
  @apply font-heading-bold text-primary text-3xl desktop:text-4xl uppercase;
}

h2 {
  @apply font-heading-bold text-primary text-2xl desktop:text-3xl uppercase;
}

h3 {
  @apply font-heading-bold text-primary text-xl desktop:text-2xl uppercase;
}

h4 {
  @apply font-heading-bold text-primary text-lg desktop:text-xl uppercase;
}

p {
  @apply text-text text-md;
}
